import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { DATA_LIMIT } from '../../data/constants';
import { companyGroupListingsNoRedux } from '../../Services/companyApis';
import { addDailySalesEntry, companyByNameListingsNoRedux, companyByNameListingsNoReduxNew, companyUserByAssociationAndCompanyListingsNoRedux, companyUserByStateListingsNoRedux, companyUserListingsByCompanyAssociationStateNoRedux, followUpByEmailListingsNoRedux, followUpByMobileListingsNoRedux, userByEmailListingsForDataNoRedux, userByEmailListingsNoRedux, userByMobileListingsNoRedux } from '../../Services/CRMFollowups';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import Divder from '../CommonComp/Divder';
import UserDetailInputField from '../User/UserDetailInputField';
import FollowUpsDetails from './FollowUpsDetails';
import LastUserFollowUps from './LastUserFollowUps';
import UserSearchList from './UserSearchList';
import * as Yup from "yup";
import UserDetailsFollowUps from './UserDetailsFollowUps';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { loginItemsSelector } from '../../redux/login';
import { useSelector } from 'react-redux';
import CreateDailySaleFollow from '../Modals/CreateDailySaleFollow';
import { useNavigate } from 'react-router-dom';
import { commonItemsSelector } from '../../redux/common';

const limit = DATA_LIMIT;

const setFormikData = (formik, userData, user) => {
    formik.setValues((values) => {
        return {
            ...values,
            email: userData?.data?.email?.trim() || "",
            alternate_email: userData?.data?.alternate_email?.trim() || "",
            alternate_second_email: userData?.data?.alternate_second_email || "",
            user_name: userData?.data?.user_name || "",
            first_name: userData?.data?.first_name || "",
            last_name: userData?.data?.last_name || "",
            short_company_name: userData?.data?.short_company_name || "",
            group_company_name: (userData && userData?.data?.company_group_name) ?
                userData?.data?.company_group_name :
                (userData && userData?.data?.group_company_name) ?
                    userData?.data?.group_company_name :
                    '' || "",
            //  userData?.data?.company_group_name || "",
            job_description: userData?.data?.job_description || "",
            company_name: userData?.data?.company_name || "",
            country: userData ? userData?.data?.country || "India" : "",
            address: userData?.data?.address || "",
            state: userData?.data?.states ? userData?.data?.states?.state_name : userData?.data?.state || '',
            phone_number: userData?.data?.phone_number || "",
            mobile_number: (userData && userData?.data?.mobile_number) ?
                userData?.data?.mobile_number :
                (userData && userData?.data?.mobile) ?
                    userData?.data?.mobile :
                    '' || "",
            alternate_name: userData?.data?.alternate_name || "",
            alternate_contact_number: userData?.data?.alternate_contact_number || "",
            designation: userData?.data?.designation || "",
           // order_id: userData?.data?.order_id || "",
             order_id:'',
            user_type: (userData?.data?.user_type || userData?.data?.user_type_id) ? 'Existing' : 'New' || "New",
            requirement_type: [],
            credit_period: userData?.data?.credit_period || '0 day',
            important: false,
            created_at: null,
            discount: userData?.data?.discount || 'NO Discount',
            ib_option: userData?.data?.ib_option || '',
            created_by: user?.userDetails?.first_name,
        }
    })
}

function CreateDailySalesEntry({ }) {
    const [followUpData, setFollowUpData] = useState([]);
    const [companyData, setCompanyData] = useState(null);
    const [associations, setAssociations] = useState([]);
    const [apiHitting,setApiHitting]=useState(false);
    const [userData, setUserData] = useState(null)
    const navigate = useNavigate();
    const commonSelector = useSelector(commonItemsSelector);
    const [userSearchData, setUserSearchData] = useState(null);
    const [userSearchDataMob, setUserSearchDataMob] = useState(null)
    const [userDataByCompanyState, setUserDataByCompanyState] = useState([])
    const [stateData, setStateData] = useState([]);
    const { user } = useSelector(loginItemsSelector);
    const [openCreateDaliySale, setOpenCreateDaliySale] = useState(false);
    const [selectedAssociation, setSelectedAssociation] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const selectRef = useRef(null);
    const selectAssociationRef = useRef(null);
    const selectRefMob = useRef(null);
    const selectAssociationRefMob = useRef(null);
    const selectRefCompany = useRef(null);
    const selectAssociationRefCompany = useRef(null);
    const [companySearch, setCompanySearch] = useState(null);

    const getCompanyListing = async (inputValue) => {

        if (inputValue && inputValue.length > 2) {
            let data = await companyByNameListingsNoRedux({
                company_name: inputValue,
                limit: 4
            })

            if (data) {
                let updatedData = data?.map((item, index) => {
                    return {
                        value: item.company_name,
                        label: item.company_name,
                        states: item.state
                    }
                })
                return updatedData || []
            }
            return []
        }
    }

    const getUserListing = async (inputValue) => {
        if (inputValue) {
            let data = await userByEmailListingsNoRedux({
                email: inputValue
            })
            if (data) {
                let updatedData = data?.map((item, index) => {
                    return {
                        value: item.email,
                        label: item.email,
                        data: item
                    }
                })
                return updatedData || []
            }
            return []
        }
    }

    const getUserListingByMobile = async (inputValue) => {
        if (inputValue) {
            let data = await userByMobileListingsNoRedux({
                mobile: inputValue
            })
            if (data) {
                let updatedData = data?.map((item, index) => {
                    return {
                        value: item.mobile_number,
                        label: item.mobile_number,
                        data: item
                    }
                })
                return updatedData || []
            }
            return []
        }
    }

    const followupApiLising = async (email) => {
        if (email) {
            let resp = await followUpByEmailListingsNoRedux({
                email: email,
                page: 1,
                limit: 4
            })
            setFollowUpData(resp?.result?.rows);
        }
    }

    const handleUserSelect = async (data) => {
        setUserData(data);
        await followupApiLising(data?.data?.email)
        setFormikData(formik, data, user)
    }

    const handleUserSearchSelect = async (e) => {
        let data = await userByEmailListingsForDataNoRedux({
            email: e?.value
        })
        if (data?.length) {
            let updatedData = {
                value: data[0].email,
                label: data[0].email,
                data: data[0]
            }
            setUserData(updatedData);
            setUserSearchData(updatedData);
            await followupApiLising(updatedData?.data?.email)
            blurAsync()
            setFormikData(formik, updatedData, user)
        }

    }

    const handleUserSearchSelectMob = async (e) => {
        setUserData({ label: e.data.email, value: e.data.email, data: e.data, mobile: e.data?.mobile_number });
        setUserSearchDataMob(e);
        // setUserData(e);
        // setUserSearchData(e);

        let resp = await followUpByMobileListingsNoRedux({
            mobile_number: e.data?.mobile_number,
            page: 1,
            limit: 4
        })
        setFollowUpData(resp?.result?.rows);
        blurAsyncMob()
        setFormikData(formik, e, user)

    }

    const handleCompanyOrGroupSelect = async (e) => {

        let data1 = await companyByNameListingsNoReduxNew({
            company_name: e.value,
        })
        if (data1) {
            let companyDataCopy = { ...e };
            if (data1?.length === 1 && data1?.length > 0) {
                let updatedData = {
                    value: data1[0]?.company_name,
                    label: data1[0]?.company_name,
                    associations: data1[0]?.association
                }
                companyDataCopy['associations'] = updatedData;
                //setCompanyData(companyDataCopy?.associations)
            }
            setCompanyData(companyDataCopy);
            // setCompanyData(e);
            if (e?.value) {
                //companyUserByAssociationAndCompanyListingsNoRedux
                let resp = await companyUserByAssociationAndCompanyListingsNoRedux({ company_name: e?.value })
                setStateData(resp);
                setCompanySearch({ state: e?.value, company_name: e?.value })
                setUserDataByCompanyState(resp?.rows);
            }


        }

        blurAsyncCompany();
    }
    const handleConfirm = async (values, { resetForm }) => {
        setApiHitting(true);
        let resp = await addDailySalesEntry({
            ...values,
            requirement_type: JSON.stringify(values?.requirement_type)
        })
        if (resp) {
            resetForm();
            setApiHitting(false);
            navigate("/crm?tab=1");
        }
    };
    const formik = useFormik({
        initialValues: {
            email: userData?.data?.email?.trim() || "",
            alternate_email: userData?.data?.alternate_email?.trim() || "",
            alternate_second_email: userData?.data?.alternate_second_email || "",
            user_name: userData?.data?.user_name || "",
            first_name: userData?.data?.first_name || "",
            last_name: userData?.data?.last_name || "",
            short_company_name: userData?.data?.short_company_name || "",
            group_company_name: (userData && userData?.data?.company_group_name) ?
                userData?.data?.company_group_name :
                (userData && userData?.data?.group_company_name) ?
                    userData?.data?.group_company_name :
                    '' || "",
            //  userData?.data?.company_group_name || "",
            job_description: userData?.data?.job_description || "",
            company_name: userData?.data?.company_name || "",
            country: userData ? userData?.data?.country || "India" : "",
            address: userData?.data?.address || "",
            state: userData?.data?.states ? userData?.data?.states?.state_name : userData?.data?.state || '',
            phone_number: userData?.data?.phone_number || "",
            mobile_number: (userData && userData?.data?.mobile_number) ?
                userData?.data?.mobile_number :
                (userData && userData?.data?.mobile) ?
                    userData?.data?.mobile :
                    '' || "",
            alternate_name: userData?.data?.alternate_name || "",
            alternate_contact_number: userData?.data?.alternate_contact_number || "",
            designation: userData?.data?.designation || "",
            // order_id: userData?.data?.order_id || "",
            order_id: "",
            user_type: (userData?.data?.user_type || userData?.data?.user_type_id) ? 'Existing' : 'New' || "New",
            requirement_type: [],
            credit_period: userData?.data?.credit_period || '0 day',
            important: false,
            created_at: null,
            discount: userData?.data?.discount || 'NO Discount',
            ib_option: userData?.data?.ib_option || '',
            created_by: user?.userDetails?.first_name,
        },
        //group_company_name

        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Please enter Valid Email')
                .required('Please enter Email'),
            mobile_number: Yup.string()
                .required("Mobile number is Required")
                .matches(/^\+?[0-9]+$/, "Must be only digits")
                .min(10, 'Mobile Number is Invalid')
                .max(18, 'Mobile Number is Invalid'),
            requirement_type: Yup.array().of(

                Yup.object().shape({

                    followup_date: Yup.string().when('followup_status', {

                        is: (v) => v != '3',

                        then: Yup.string().required('Follow up date is required'),

                        otherwise: Yup.string(),

                    }),

                    description_type: Yup.string().required('Description type is required'),

                }, ['followup_status'])

            ).min(1, 'Please select requirement type'),

            discount: Yup.string().required('Discount Term is required.'),
            first_name: Yup.string().required('First Name is required.'),
            // last_name: Yup.string().required('Last Name is required.'),
            country: Yup.string().required('Country is required.'),
            company_name: Yup.string().required('Company Name is required.'),
            ...(commonSelector?.stateData?.isStateAvailable === true ? { state: Yup.string().required('State is required.') } : {})
        }),
        onSubmit: handleConfirm,
    });

    const handleCompanyStatesChange = async (value) => {
        if (value) {
            let resp = await companyUserListingsByCompanyAssociationStateNoRedux({ state: value, company_name: companyData?.value, association: selectedAssociation, limit: 50, page: 1 })
            setCompanySearch({ state: value, company_name: companyData?.value, association: selectedAssociation })
            setUserDataByCompanyState(resp?.rows);
            if (resp) {
                if (resp?.rows?.length) {
                    setUserData({ data: resp?.rows[0] })
                }
            }

        }
    }

    const handleCompanyAssociationChange = async (value) => {
        let resp = await companyUserByAssociationAndCompanyListingsNoRedux({ company_name: companyData?.value, association: value })
        setSelectedAssociation(value);
        setStateData(resp);
        setCompanySearch({ state: value, company_name: companyData?.value });
        setUserDataByCompanyState(resp?.rows);

    }

    const handleCreate = (value) => {
        setOpenCreateDaliySale(value);
        if (!value) {
            setSelectedData(null)
        }
    };

    const handleSelectedData = (data) => {
        setSelectedData(data);
        handleCreate(true)
    }

    const blurAsync = () => {
        selectRef.current?.blur();
        selectAssociationRef.current?.blur();
    };

    const blurAsyncMob = () => {
        selectRefMob.current?.blur();
        selectAssociationRefMob.current?.blur();
    };

    const blurAsyncCompany = () => {
        selectRefCompany.current?.blur();
        selectAssociationRefCompany.current?.blur();
    };


    return (
        <>
            <Box component={'form'} onSubmit={formik.handleSubmit}>
                <CustomBox padding={3}>
                    <Typography variant='h5'>User Search</Typography>
                    <Divder spacing={1} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6}>
                            <CustomAsyncSelect
                                promiseOptions={getUserListing}
                                // error={error}
                                // helperText={helperText}
                                closeMenuOnSelect={false}
                                controlStyle={{
                                    padding: 5,
                                    height: 56
                                }}
                                cacheOptions={false}
                                value={userSearchData}
                                handleChange={(e) => {
                                    handleUserSearchSelect(e)
                                }}
                                width={'100%'}
                                ref={selectRef}
                                dropDownZIndex={100000}
                                labelShrink={true}
                                label="Email / Alternate Email ID"
                                isMulti={false}
                                onFocus={() => {
                                    setCompanyData(null)
                                    setUserData(null)
                                    setUserSearchData(null)
                                    setUserSearchDataMob(null)
                                    setFollowUpData([])
                                    setUserDataByCompanyState([])
                                    setSelectedAssociation(null)
                                    setStateData([])
                                    formik.resetForm()
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} sm={6}>
                            <CustomAsyncSelect
                                promiseOptions={getUserListingByMobile}
                                closeMenuOnSelect={false}
                                controlStyle={{
                                    padding: 5,
                                    height: 56
                                }}
                                cacheOptions={false}

                                value={userSearchDataMob}
                                handleChange={(e) => {
                                    handleUserSearchSelectMob(e)
                                }}
                                width={'100%'}
                                ref={selectRefMob}
                                dropDownZIndex={100000}
                                labelShrink={true}
                                label="Mobile Number"
                                isMulti={false}
                                onFocus={() => {
                                    setCompanyData(null)
                                    setUserData(null)
                                    setUserSearchData(null)
                                    setUserSearchDataMob(null)
                                    setFollowUpData([])
                                    setUserDataByCompanyState([])
                                    setSelectedAssociation(null)
                                    setStateData([])
                                    formik.resetForm()
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} marginTop={1}>
                        <Grid item xs={12} md={4}>
                            <CustomAsyncSelect
                                promiseOptions={getCompanyListing}
                                closeMenuOnSelect={false}
                                controlStyle={{
                                    padding: 5,
                                    height: 56
                                }}
                                cacheOptions={false}
                                value={companyData}
                                handleChange={(e) => {
                                    handleCompanyOrGroupSelect(e)
                                }}
                                width={'100%'}
                                dropDownZIndex={100000}
                                ref={selectRefCompany}
                                labelShrink={true}
                                label="Company / Company Master"
                                isMulti={false}
                                onFocus={() => {
                                    setCompanyData(null)
                                    setUserData(null)
                                    setUserSearchData(null)
                                    setUserSearchDataMob(null)
                                    setFollowUpData([])
                                    setUserDataByCompanyState([])
                                    setSelectedAssociation(null)
                                    setStateData([])
                                    formik.resetForm()
                                }}
                            />
                        </Grid>
                        {companyData?.associations &&
                            <Grid item xs={12} md={4}>

                                {companyData?.associations?.associations?.length ? (<UserDetailInputField
                                    name="company_assocation"
                                    label="Associations"
                                    placeholder="Association"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'rounded',
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleCompanyAssociationChange(e.target.value);

                                    }}
                                >
                                    <option key={'Select Association'} value={''}>
                                        Select Association
                                    </option>
                                    {companyData?.associations && companyData?.associations?.associations?.map((ass, index) => {
                                        return (
                                            <option key={ass.association + index} value={ass.association}>
                                                {ass.association}
                                            </option>
                                        );
                                    })}
                                </UserDetailInputField>) : null}

                            </Grid>
                        }
                        {stateData?.length > 0 &&
                            <Grid item xs={12} md={4}>
                                {stateData?.length ? (<UserDetailInputField
                                    name="state"
                                    label="States"
                                    placeholder="States"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    select
                                    value={companySearch?.state}
                                  
                                    // sx={{ mt: 2, maxWidth: 220 }}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'rounded',
                                        },
                                    }}
                                    onChange={(e) => {
                                        handleCompanyStatesChange(e.target.value);
                                    }}
                                >
                                    <option key={'Select State'} value={''}>
                                        Select State
                                    </option>
                                    {stateData && stateData?.map((state, index) => {
                                        return (
                                            <option key={state.state + index} value={state.state}>
                                                {state.state}
                                            </option>
                                        );
                                    })}
                                </UserDetailInputField>) : null}


                            </Grid>
                        }

                    </Grid>
                </CustomBox>
                {userDataByCompanyState?.length ? <>
                    <Divder spacing={1} />
                    <UserSearchList data={userDataByCompanyState} handleUserSelect={handleUserSelect} companySearch={companySearch} />
                </> : null}
                {followUpData.length ? <>
                    <Divder spacing={1} />
                    <LastUserFollowUps data={followUpData} handleSelectedData={handleSelectedData} userData={userData} followupApiLising={followupApiLising} />
                </> : null}
                <Divder spacing={1} />
                <CustomBox padding={3}>
                    <UserDetailsFollowUps formik={formik} userData={userData} />
                    <Divder spacing={1} />
                    <FollowUpsDetails formik={formik} userData={userData} />
                    <Divder spacing={1} />
                    <Box textAlign={'right'}>
                        <CustomButton type="submit" color={'buttonPrimary'} disabled={apiHitting}>
                            Submit
                        </CustomButton>
                    </Box>
                </CustomBox>
            </Box>
            {openCreateDaliySale && <CreateDailySaleFollow
                open={openCreateDaliySale}
                handleOpen={handleCreate}
                getListing={() => {
                    followupApiLising(userData?.value)
                }}
                selectedData={selectedData}
            />}
        </>
    );
}

export default CreateDailySalesEntry;
