import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';

function ContributorPDFView({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);
  const { fileName } = useParams();
  const navigate=useNavigate();
  const theme = useTheme();
  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox sx={{marginBottom:'10px'}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <LeftArrow fill={theme.palette.textButton.main} />
            <Typography
              variant="h5"
              sx={{
                marginLeft: theme.spacing(1),
                color: theme.palette.textButton.main,
              }}
            >
              Back
            </Typography>
          </Box>
        </Box>
      </CustomBox>
      <CustomBox padding={0}>
        {/* <iframe
        src={`https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/image_noc/${fileName}`}
        width="100%"
        height="600px"
        title="PDF Viewer"
        style={{ border: 'none' }}
      /> */}
        <embed
          src={`https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/image_noc/${fileName}`}
          width="100%"
          height="600px"
          type="application/pdf"
          title="PDF Viewer"
        />
      </CustomBox>
    </Box>
  );
}

export default ContributorPDFView;
