import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  contributorList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorPaymentList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorFolderList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorSoldImagesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorUploadedImagesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorListUpload: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorBasicInfo: {
    error: {
      isError: false,
      message: ''
    },
    items: {},
    loading: false
  },
  contributorPaymentData: {
    error: {
      isError: false,
      message: ''
    },
    items: {},
    loading: false
  },
  contributorPaymentDataForImages: {
    error: {
      isError: false,
      message: ''
    },
    items: {},
    loading: false
  },
  contributorPaymentDivisionForImages: {
    error: {
      isError: false,
      message: ''
    },
    items: {},
    loading: false
  },
  contributorDailySalesReport: {
    error: {
      isError: false,
      message: ''
    },
    items: {},
    loading: false
  },
  contributorQueriesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  contributorNocsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const contributorSlice = createSlice({
  name: 'contributor',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setContributorListingLoading: (state, action) => {
      state.contributorList.loading = action.payload
    },
    setContributorListing: (state, action) => {
      state.contributorList.items = action.payload;
      state.contributorList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorFolderListing: (state, action) => {
      state.contributorFolderList.items = action.payload;
      state.contributorFolderList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorListingError: (state, action) => {
      state.contributorList.items = {};
      state.contributorList.error = action.payload;
    },
    setContributorUploadListingError: (state, action) => {
      state.contributorListUpload.items = {};
      state.contributorListUpload.error = action.payload;
    },
    setContributorFolderListingError: (state, action) => {
      state.contributorFolderList.items = {};
      state.contributorFolderList.error = action.payload;
    },
    setContributorFolderListingLoading: (state, action) => {
      state.contributorFolderList.loading = action.payload
    },
    setContributorPaymentListingLoading: (state, action) => {
      state.contributorPaymentList.loading = action.payload
    },
    setContributorPaymentListing: (state, action) => {
      state.contributorPaymentList.items = action.payload;
      state.contributorPaymentList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorPaymentListingError: (state, action) => {
      state.contributorPaymentList.items = {};
      state.contributorPaymentList.error = action.payload;
    },
    setContributorSoldImagesListingLoading: (state, action) => {
      state.contributorSoldImagesList.loading = action.payload
    },
    setContributorSoldImagesListing: (state, action) => {
      state.contributorSoldImagesList.items = action.payload;
      state.contributorSoldImagesList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorSoldImagesListingError: (state, action) => {
      state.contributorSoldImagesList.items = {};
      state.contributorSoldImagesList.error = action.payload;
    },
    setContributorUploadListingLoading: (state, action) => {
      state.contributorListUpload.loading = action.payload
    },
    setContributorUploadListing: (state, action) => {
      state.contributorListUpload.items = action.payload;
      state.contributorListUpload.error = {
        isError: false,
        message: '',
      };
    },
    setContributorUploadedImagesListingLoading: (state, action) => {
      state.contributorUploadedImagesList.loading = action.payload
    },
    setContributorUploadedImagesListing: (state, action) => {
      state.contributorUploadedImagesList.items = action.payload;
      state.contributorUploadedImagesList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorUploadedImagesListingError: (state, action) => {
      state.contributorUploadedImagesList.items = {};
      state.contributorUploadedImagesList.error = action.payload;
    },




    setContributorBasicLoading: (state, action) => {
      state.contributorBasicInfo.loading = action.payload
    },
    setContributorBasicData: (state, action) => {
      state.contributorBasicInfo.items = action.payload;
      state.contributorBasicInfo.error = {
        isError: false,
        message: '',
      };
    },
    setContributorBasicDataError: (state, action) => {
      state.contributorBasicInfo.items = {};
      state.contributorBasicInfo.error = action.payload;
    },

    setContributorPaymentHistory: (state, action) => {
      state.contributorPaymentData.items = action.payload;
      state.contributorPaymentData.error = {
        isError: false,
        message: '',
      };
    },
    setContributorPaymentLoading: (state, action) => {
      state.contributorPaymentData.loading = action.payload;
    },
    setContributorPaymentError: (state, action) => {
      state.contributorPaymentData.items = {};
      state.contributorPaymentData.error = action.payload;
    },

    setContributorPaymentHistoryForImage: (state, action) => {
      state.contributorPaymentDataForImages.items = action.payload;
      state.contributorPaymentDataForImages.error = {
        isError: false,
        message: '',
      };
    },
    setContributorPaymentLoadingForImage: (state, action) => {
      state.contributorPaymentDataForImages.loading = action.payload;
    },
    setContributorPaymentErrorForImage: (state, action) => {
      state.contributorPaymentDataForImages.items = {};
      state.contributorPaymentDataForImages.error = action.payload;
    },


    setContributorPaymentDivisionForImage: (state, action) => {
      state.contributorPaymentDivisionForImages.items = action.payload;
      state.contributorPaymentDivisionForImages.error = {
        isError: false,
        message: '',
      };
    },
    setContributorDivisionLoadingForImage: (state, action) => {
      state.contributorPaymentDivisionForImages.loading = action.payload;
    },
    setContributorDivisionErrorForImage: (state, action) => {
      state.contributorPaymentDivisionForImages.items = {};
      state.contributorPaymentDivisionForImages.error = action.payload;
    },

    setResetContributorPaymentDivision: (state, action) => {
      state.contributorPaymentDivisionForImages.items = {};
      state.contributorPaymentDivisionForImages.loading = false;
      state.contributorPaymentDivisionForImages.error = {
        isError: false,
        message: '',
      };
    },
    setContributorDailySalesReportListingLoading: (state, action) => {
      state.contributorDailySalesReport.loading = action.payload
    },
    setContributorDailySalesReportListing: (state, action) => {
      state.contributorDailySalesReport.items = action.payload;
      state.contributorDailySalesReport.error = {
        isError: false,
        message: '',
      };
    },
    setContributorDailySalesReportListingError: (state, action) => {
      state.contributorDailySalesReport.items = {};
      state.contributorDailySalesReport.error = action.payload;
    },
    setResetContributorDailySalesReport:(state,action)=>{
      state.contributorDailySalesReport.items = {};
      state.contributorDailySalesReport.error = {
        isError: false,
        message: '',
      };
      state.contributorDailySalesReport.loading = false
    },
    setContributorQueryListingError: (state, action) => {
      state.contributorQueriesList.items = {};
      state.contributorQueriesList.error = action.payload;
    },
    setContributorQueryListingLoading: (state, action) => {
      state.contributorQueriesList.loading = action.payload
    },
    setContributorQueryListing: (state, action) => {
      state.contributorQueriesList.items = action.payload;
      state.contributorQueriesList.error = {
        isError: false,
        message: '',
      };
    },
    setContributorNocListingError: (state, action) => {
      state.contributorNocsList.items = {};
      state.contributorNocsList.error = action.payload;
    },
    setContributorNocListingLoading: (state, action) => {
      state.contributorNocsList.loading = action.payload
    },
    setContributorNocListing: (state, action) => {
      state.contributorNocsList.items = action.payload;
      state.contributorNocsList.error = {
        isError: false,
        message: '',
      };
    },
  },
});

export default contributorSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setContributorListing,
  setContributorListingError,
  setContributorSoldImagesListing,
  setContributorSoldImagesListingError,
  setContributorUploadedImagesListing,
  setContributorFolderListing,
  setContributorFolderListingLoading,
  setContributorFolderListingError,
  setContributorUploadedImagesListingError,
  setContributorUploadListing,
  setContributorUploadListingError,
  setContributorUploadListingLoading,
  setContributorListingLoading,
  setContributorSoldImagesListingLoading,
  setContributorUploadedImagesListingLoading,
  setContributorBasicDataError,
  setContributorBasicData,
  setContributorBasicLoading,
  setContributorPaymentError,
  setContributorPaymentLoading,
  setContributorPaymentHistory,
  setContributorPaymentHistoryForImage,
  setContributorPaymentLoadingForImage,
  setContributorPaymentErrorForImage,
  setContributorPaymentDivisionForImage,
  setContributorDivisionLoadingForImage,
  setContributorDivisionErrorForImage,
  setResetContributorPaymentDivision,
  setContributorDailySalesReportListing,
  setContributorDailySalesReportListingError,
  setContributorDailySalesReportListingLoading,
  setContributorPaymentListingLoading,
  setContributorPaymentListing,
  setResetContributorDailySalesReport,
  setContributorQueryListingLoading,
  setContributorQueryListing,
  setContributorQueryListingError,
  setContributorNocListingError,
  setContributorNocListingLoading,
  setContributorNocListing

} = contributorSlice.actions;

export const contributorItemsSelector = (state) => state.contributor;
