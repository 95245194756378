import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import ConfirmationModal from '../Modals/ConfirmationModal';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import {
  listOfRejectionreasons,
  deleteRejectionreasons,
} from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import AddEditReasonModal from '../Modals/AddEditReasonModal';

const limit = 40;

const column = [
  {
    label: 'ID',
    id: 'reason_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Reason',
    id: 'reason',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 400, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
];

function RejectionReason() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, rejectionData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const [addReasonModal, setAddReasonModal] = useState(false);
  const [editReasonModal, setEditReasonModal] = useState(false);
  const [selectedRejection, setSelectedRejection] = useState(null);
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const [deleteRejectionData, setDeleteRejectionData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const handleAdd = (value = false) => {
    setAddReasonModal(value);
  };
  const handleEdit = (value = false) => {
    setEditReasonModal(value);
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };
    dispatch(listOfRejectionreasons(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  useEffect(() => {
    if (rejectionData?.items?.rows?.length >= 0) {
      setTableListing(rejectionData?.items?.rows);
    }
  }, [rejectionData.items]);

  const handleConfirmDeleteCategory = async (value) => {
    if (value && deleteRejectionData) {
      const resp = await deleteRejectionreasons({
        reason_id: deleteRejectionData,
      });
      if (resp) {
        getListing({ page: '1' });
        setPage(1);
      }
    } else {
      setDeleteRejectionData(null);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCategory(value);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="noMinWidth buttonDense"
            onClick={() => {
              setSelectedRejection(data);
              handleEdit(true);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            className="noMinWidth buttonDense"
            onClick={() => {
              handleCofirmModalOpen(true);
              setDeleteRejectionData(data?.reason_id);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Delete />
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={rejectionData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={rejectionData?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolBarButton={() => {
          return (
            <>
              <CustomButton
                onClick={() => {
                  handleAdd(true);
                }}
                color={'buttonPrimary'}
                sx={(theme) => ({
                  padding: '10px 10px',
                  marginRight: 1,
                  [theme.breakpoints.down('md')]: {
                    flex: 1,
                  },
                })}
              >
                Add Reason
              </CustomButton>
            </>
          );
        }}
        toolbarEnable={true}
        pagination={true}
      />
      {addReasonModal && (
        <AddEditReasonModal
          open={addReasonModal}
          setPage={setPage}
          type={'add'}
          handleOpen={handleAdd}
          handleLast={getListing}
        />
      )}
      {confirmDeleteCategory && (
        <ConfirmationModal
          open={confirmDeleteCategory}
          handleConfirm={handleConfirmDeleteCategory}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {editReasonModal && (
        <AddEditReasonModal
          open={editReasonModal}
          setPage={setPage}
          handleOpen={handleEdit}
          type={'edit'}
          data={selectedRejection}
          setSelectedRejection={setSelectedRejection}
          handleLast={getListing}
        />
      )}
    </>
  );
}

export default RejectionReason;
