import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { DATA_LIMIT } from '../../data/constants';


const limit = DATA_LIMIT;

function ViewImage({ open, handleOpen, data }) {
  const [loading, setLoading] = useState(false);
  const modalContainerRef = useRef(null);
  const modalRef = useRef(null);


  const handleModal = (e) => {
    if (modalContainerRef.current !== e.target) {
      handleOpen(false);
    } else if (modalRef.current === e.target) {
      e.stopPropagation();
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        ref={modalContainerRef}
        onClose={() => {
          // handleOpen(false);
        }}
        onClick={(e)=>{handleModal(e)}}
      >
         <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 0,marginLeft:0 

               }}
            >
              <button
                style={{backgroundColor:'#b30000',width:'40px',color:'white'}}
                onClick={() => {

                  handleOpen(false)
                }
                }
                disabled={loading}
              >
               X
              </button>
            </div>
          {/* <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: -10,marginLeft:100 }}
            >

              <CustomButton
                style={{backgroundColor:'#b30000'}}
                onClick={() => {

                  handleOpen(false)
                }
                }
                disabled={loading}
              >
               X
              </CustomButton>
            </div> */}
        <DialogTitle variant="h3" padding={3}>
        Image:{data?.Name || data?.name}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              width: 400
            }}

          >
            <Grid container spacing={2}>
              
                <Grid item xs={12} sm={12} md={12}>
                  {/* <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight500'>Mid Shot</Typography> </span> */}

                  <img src={`${data?.image_url}`} className='img-fluid' />

                </Grid>
              


            </Grid>
          
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewImage;
