import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import {
    uploadImagesContributorListings,
} from '../../Services/ContributorApis';
import MatTable from '../Tables/MatTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import CustomBox from '../CommonComp/CustomBox/CustomBox';

const limit = 10;

const column = [
    {
        label: 'ID',
        id: 'contributor_id',
        format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
            if (row?.contributor_id) {
                return columnData ? <Link className='textDecoration-none link-text' to={`fileFolderList/${row?.contributor_id}`} state={row?.contributor_id}  >{columnData}</Link> :
                    <Typography variant='span' style={{ whiteSpace: 'nowrap' }}>{columnData}</Typography>
            }
            return null
        },

    },
    {
        label: 'Email',
        id: 'contributor',
        format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                {columnData.email || 'N/A'}
            </span>
        ),
    },
    {
        label: 'Name',
        id: 'contributor',
        format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
            <span
                style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
            >
                {columnData.name || 'N/A'}
            </span>
        ),
    },
];

function ContributorUploadList() {
    const [tableListing, setTableListing] = useState([]);
    const [page, setPage] = useState(1);
    const [allParams, setAllParams] = useState(getParameters());
    const { contributorListUpload } = useSelector(contributorItemsSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const getListing = async (params = {}) => {
        const payload = {
            page: String(1),
            limit: limit,
            order_status: String(1),
            ...allParams,
            ...params,
        };
        dispatch(uploadImagesContributorListings(payload));
        setPage(Number(payload.page));
    };

    useEffect(() => {
        getListing();
    }, []);

    useEffect(() => {
        let params = getParameters()
        setAllParams(params);
    }, [location?.search]);

    useEffect(() => {
        let data = getNewParameters(allParams)
        if (data?.search !== location.search) {
            navigate(data.newUrl, { replace: false })
            if (data?.parameters?.page) {
                setPage(Number(data?.parameters?.page))
            }
        }
        getListing();
    }, [allParams])
    useEffect(() => {
        if (contributorListUpload?.items?.data?.length >= 0) {
            setTableListing(contributorListUpload?.items?.data);
        }
    }, [contributorListUpload.items]);
    const actionBody = (data) => {
        return (
            <>
                <div style={{ display: 'flex' }}></div>
            </>
        );
    };

    return (
        <CustomBox padding={0} sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <MatTable
                loading={contributorListUpload?.loading}
                showCheckbox={false}
                columns={column}
                data={tableListing}
                onApplyFilter={(data = {}) => {
                    getListing({
                        page: String(1),
                        start_date: '',
                        end_date: '',
                        ...getObjectSubset(data, 'start_date', 'end_date'),
                    });
                    if (Object.keys(data).length > 0) {
                        setAllParams({
                            ...allParams,
                            page: String(1),
                            ...getObjectSubset(data, 'start_date', 'end_date'),
                        });
                    } else {
                        setAllParams({
                            page: String(1),
                        });
                    }
                }}
                page={page}
                perPage={limit}
                total={contributorListUpload?.items?.totalCount || 0}
                setPage={setPage}
                handleSearch={(searchTerm) => {
                    getListing({
                        page: String(1),
                        search: searchTerm,
                    });
                    setAllParams({
                        ...allParams,
                        page: String(1),
                        search: searchTerm,
                    });
                }}
                handlePageChange={(newPage) => {
                    getListing({
                        page: String(newPage),
                    });
                    setAllParams({
                        ...allParams,
                        page: String(newPage),
                    });
                }}
                toolbarEnable={true}
                toolBarButton={() => {

                }}
                pagination={true}
                filtersUrlData={allParams || {}}
                filterEnable
                rangeDateFilter
            />

        </CustomBox>
    );
}

export default ContributorUploadList;
