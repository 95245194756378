export const CmsData = {
  tabs: [
    {
      label: 'Footer/Header',
      id: 1,
    },
    {
      label: 'Home Page',
      id: 2,
    },
    {
      label: 'About US',
      id: 3,
    },
    {
      label: 'Testimonials',
      id: 4,
    },
    {
      label: 'Contacts',
      id: 5,
    },
    {
      label: `FAQ's`,
      id: 6,
    },
    {
      label: `Searching Tips`,
      id: 7,
    },
    {
      label: `Ordering Tips`,
      id: 8,
    },
    {
      label: `Downloading Tips`,
      id: 9,
    },
    {
      label: `Licensing`,
      id: 10,
    },
    {
      label: `Wishlist`,
      id: 11,
    },
    {
      label: `My Account`,
      id: 12,
    },
    {
      label: `Glosarry`,
      id: 13,
    },
    {
      label: `Terms Of Use`,
      id: 14,
    },
    {
      label: `Privacy Policy`,
      id: 15,
    },
    {
      label: `Office Address`,
      id: 16,
    },
    {
      label: `Customer Support`,
      id: 17,
    },
    {
      label: `Image Pricing`,
      id: 18,
    },
    {
      label: `Video Pricing`,
      id: 19,
    },
    {
      label: `Technical`,
      id: 20,
    },
    {
      label: `Background Image`,
      id: 21,
    },
    // {
    //   label: `Banners`,
    //   id: 22,
    // },
    {
      label: `Banners Images`,
      id: 22,
    },
    {
      label: `Rejection Reason`,
      id: 23,
    },
  ],
};
