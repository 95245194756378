export const ContributorsData = {
  tabs: [
    {
      label: 'Contributor',
      id: 1,
    },
    {
      label: 'Contributor Sold Images',
      id: 2,
    },
    {
      label: 'GST Sales Report',
      id: 3,
    },
    {
      label: 'Contributor Uploaded Images',
      id: 4,
    },
    {
      label: 'Contributor Queries',
      id: 5,
    }
  ],
};

export const DepartmentData = [
  { label: 'HR', value: 'HR' },
  { label: 'Account', value: 'Account' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Testing', value: 'Testing' }
]

export const ContributorData = {
  tabs: [
    {
      label: 'Contributor Info',
      id: 1,
    },
    {
      label: 'Bank Detail',
      id: 2,
    },
    {
      label: 'Payment Detail',
      id: 3,
    },
    {
      label: 'NOC',
      id: 4,
    },
   
  ],
};
export const QueryStatus = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Resolved',
    value: '1',
  },
  {
    label: 'Pending',
    value: '0',
  }  
];
// export const SearchBy = [
//   {
//     label: 'Select',
//     value: ''
//   },
//   {
//     label: 'Email',
//     value: '0',
//   },
//   {
//     label: 'Name',
//     value: '1',
//   },
//   {
//     label: 'Mobile',
//     value: '2',
//   },
//   {
//     label: 'State',
//     value: '3',
//   }
// ];

