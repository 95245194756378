import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { deleteApiPayload, errorMessage } from '../lib/helper';

import {
  setCmsData,
  setCmsError,
  setCmsFooterData,
  setCmsFooterError,
  setFAQSDataData,
  setFAQSDataError,
  setFAQSDataLoading,
  setImagePriceDataError,
  setImagePriceDataLoading,
  setImagePriceListData,
  setLoading,
  setTestmonialData,
  setTestmonialError,
  setTestmonialLoading,
  setVideoPriceDataError,
  setVideoPriceDataLoading,
  setVideoPriceListData,
  setDownloadTimeDataLoading,
  setDownloadTimeData,
  setDownloadTimeDataError,
  setbannersImagesDataLoading,
  setbannersImagesListData,
  setbannersImagesDataError,
  setHorizontalDataLoading,
  setHorizontalDataError,
  setHorizontalData,
  setVerticalLoading,
  setVerticalData,
  setVerticalDataError,
  setRejectionReasonData,
  setRejectionReasonDataError,
  setRejectionReasonDataLoading,
} from '../redux/cms';

export const getCMSInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCmsData}`, {
        params: payload,
      });
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setCmsData(resp.data.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setCmsError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const getBannersInfo = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setbannersImagesDataLoading(true));
      const resp = await axios.get(`${ApiConfig.bannersImages}`, {
        params: payload,
      });
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setbannersImagesListData(resp.data));
        dispatch(setbannersImagesDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setbannersImagesDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setbannersImagesDataLoading(false));
    }
  };
};

export const deleteBannerImage = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteBannerImage}`, deleteApiPayload(payload));
      if (resp?.data?.statusCode >= STATUS_200 && resp?.data?.statusCode < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return resp?.data;
      } else {
        throw resp?.data
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const getTestimonial = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.post(`${ApiConfig.listOfTestimonial}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setTestmonialData(resp.data.data));
        dispatch(setTestmonialLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setTestmonialError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setTestmonialLoading(false));
    }
  };
};

export const createTestimonail = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createTestimonial}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const listOfFollowedBy = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.listOfFollowedBy}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp.data?.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const editTestimonial = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editTestimonial}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteTestimonial = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteTestimonial}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getCMSFooter = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCmsData}`, {
        params: payload,
      });
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setCmsFooterData(resp.data.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setCmsFooterError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const editAddCMS = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.postCMSHomeData}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getFAQSListing = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setFAQSDataLoading(true));
      const resp = await axios.post(`${ApiConfig.listOfFaq}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setFAQSDataData(resp.data.data));
        dispatch(setFAQSDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setFAQSDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setFAQSDataLoading(false));
    }
  };
};

export const createFaq = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createFaq}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editFaq = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editFaq}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteFaq = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteFaq}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getImagePricing = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.post(`${ApiConfig.imagePriceList}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setImagePriceListData(resp.data.data));
        dispatch(setImagePriceDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setImagePriceDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setImagePriceDataLoading(false));
    }
  };
};

export const getDownloadTimingData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setDownloadTimeDataLoading(true));
      const resp = await axios.post(`${ApiConfig.downloadTimingList}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setDownloadTimeData(resp.data.data));
        dispatch(setDownloadTimeDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setDownloadTimeDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setDownloadTimeDataLoading(false));
    }
  };
};
export const getHorizontalImageData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setHorizontalDataLoading(true));
      const resp = await axios.post(`${ApiConfig.horizontalImagedimention}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setHorizontalData(resp.data.data));
        dispatch(setHorizontalDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setHorizontalDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setHorizontalDataLoading(false));
    }
  };
};
export const getVerticalImageData = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setVerticalLoading(true));
      const resp = await axios.post(`${ApiConfig.horizontalImagedimention}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setVerticalData(resp.data.data));
        dispatch(setVerticalLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setVerticalDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setVerticalLoading(false));
    }
  };
};


export const getVideoPricing = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.post(`${ApiConfig.videoPriceList}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setVideoPriceListData(resp.data.data));
        dispatch(setVideoPriceDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setVideoPriceDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setVideoPriceDataLoading(false));
    }
  };
};

export const editImagePrice = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editImagePrice}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const editDownloadTimeData = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.editDownloadTimeData}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const editVerticalHorizontalData = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.editVerticalHorizontal}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const editVideoPrice = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editVideoPrice}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const createRejections = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createRejections}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const listOfRejectionreasons = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.post(`${ApiConfig.listOfRejectionreasons}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setRejectionReasonData(resp.data.data));
        dispatch(setRejectionReasonDataLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch(
        setRejectionReasonDataError({
          isError: true,
          message: errorMessage(e),
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setRejectionReasonDataLoading(false));
    }
  };
};
export const listOfRejectionreason = async(payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.listOfRejectionreasons}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  } 
};
export const deleteRejectionreasons = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteRejectionreasons}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const editRejectionreasons = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editRejectionreasons}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};