import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { UserRegister } from '../../Services/LoginApi';
import { temporaryUserListing } from '../../Services/userApis';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCountry,
  getJobDescription,
  getState,
} from '../../Services/commonService';
import { commonItemsSelector } from '../../redux/common';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { updateImageStatus } from '../../Services/ContributorApis';

function RejectedReasonsModal({
  open,
  handleOpen,
  setOpen,
  handleLast,
  data,
  rejectionReasons,
  status,
  updateData,
  setUpdateData,
  getListing,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedState, setSelectedState] = useState([]);

  // const handleConfirm = async (values, { resetForm }) => {
  //   setLoading(true)
  //   let payload = {
  //     ...values,
  //     mobile: values?.mobile?.includes('+')
  //       ? values?.mobile
  //       : '+' + values?.mobile,
  //   }
  //   let resp = await UserRegister(payload)
  //   if (resp) {
  //     handleLast();
  //     resetForm({
  //       email: '',
  //       password: '',
  //       fName: '',
  //       lName: '',
  //       companyName: '',
  //       country: '',
  //       state: '',
  //       jobDescription: '',
  //       mobile: '',
  //       address: '',
  //     })
  //     dispatch(temporaryUserListing({
  //       page: String(1),
  //       limit: 10,
  //       user_type: "1",
  //     }))
  //     handleOpen(false)
  //   }
  //   setLoading(false)
  // };

  const getReasonListing = async (inputValue) => {
    if (rejectionReasons) {
      let updatedData = rejectionReasons?.map((reason, index) => {
        return {
          value: reason?.reason_id,
          label: reason?.reason,
        };
      });
      return updatedData || [];
    }
  };
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let comments = values.rejectionReason
      ?.map((item) => item?.label)
      ?.join(',');
    if (status && updateData) {
      const resp = await updateImageStatus({
        contributor_upload_image_id: updateData,
        status: status,
        comment: comments,
      });
      if (resp) {
        getListing();
        setUpdateData(null);
        resetForm({
          comment: '',
          rejectionReason: '',
        });
        setOpen(false);
        setLoading(false);
      } else {
        setUpdateData(null);
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      rejectionReason: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      rejectionReason: Yup.array().min(1, 'At least one item is required'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        PaperProps={{
          style: { width: '600px', maxWidth: '100%' }, // Set your desired width here
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Reason for Rejection
        </DialogTitle>
        <DialogContent>
          <Box noValidate component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomAsyncSelect
                  promiseOptions={getReasonListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                    minHeight: '56px',
                  }}
                  cacheOptions={true}
                  value={formik.values.rejectionReason}
                  error={
                    formik.touched.rejectionReason &&
                    Boolean(formik.errors.rejectionReason)
                  }
                  helperText={
                    formik.touched.rejectionReason &&
                    formik.errors.rejectionReason
                  }
                  handleChange={(e) => {
                    setSelectedState(e);
                    formik.setFieldValue('rejectionReason', e);
                  }}
                  width={'100%'}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="Reason"
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add Reason
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    password: '',
                    fName: '',
                    lName: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RejectedReasonsModal;
