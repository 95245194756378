import React, { useState } from 'react'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import ContributorPDFView from './ContributorPDFView'

function ContributorPdfViewContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "NOC",
    navSubTitle: "Welcome to ImagesBazaar"
})


const handleNavParams = (obj)=>{
  setNavParams({
      ...navParams,
      ...obj
  })

}
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle} >
      <ContributorPDFView handleNavParams={handleNavParams} />
    </MainLayout>
  )
}

export default ContributorPdfViewContainer