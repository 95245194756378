import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';

// Format for component
// [
//     {
//         label: 'Assign to',
//         id: 'assign_to',
//         type: 'select',
//         linkedWith: null,
//         value: 'all',
//         onChange: (e, index, value)=>{
//           setFiltersArray((prevState)=>{
//             let newState = [...prevState]
//             newState[index].value = e.target.value
//             return newState
//           })
//         },
//         style:{},
//         dataArr: [{label: 'All', value: 'all'}, {label: 'Aastha', value: 'aastha'}],
//         props: {},
//         isVisible: true
//       }
// ]

function MasterReportFilters({
  filtersArray,
  handleClick,
  handleClear,
  hasDownloadExcelButton,
  handleDownloadExcel,
}) {
  if (filtersArray?.length < 0) {
    return null;
  }
  return (
    <>
      <Grid spacing={2} py={0.5} container>
        {filtersArray?.map((item, index) => {
          if (!item?.isVisible) {
            return null;
          }
          return (
            <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
              {item?.type == 'select' ? (
                item?.selectType == 'async-select' ? (
                  <CustomAsyncSelect
                    promiseOptions={item?.getListings}
                    // error={error}
                    // helperText={helperText}
                    closeMenuOnSelect={false}
                    controlStyle={{
                      padding: 5,
                      height: 56,
                    }}
                    cacheOptions={false}
                    value={item?.listingData}
                    handleChange={(e) => {
                      item?.handleChangeAsyncSelect(e);
                    }}
                    width={'100%'}
                    dropDownZIndex={100000}
                    ref={item?.asyncRef}
                    labelShrink={true}
                    label={item?.label}
                    isMulti={false}
                    onFocus={() => {
                      item?.setListingData(null);
                    }}
                    {...item.props}
                  />
                ) : (
                  <CustomInput
                    select
                    SelectProps={{
                      native: true,
                    }}
                    name={item?.id}
                    label={item?.label}
                    placeholder={item?.label}
                    value={item.value}
                    onChange={(e) => item?.onChange(e, index, item)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    sx={{
                      width: '100%',
                    }}
                    {...item.props}
                  >
                    {item?.dataArr?.length &&
                      item?.dataArr?.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </CustomInput>
                )
              ) : (
                <CustomInput
                  name={item?.id}
                  key={index}
                  label={item?.label}
                  placeholder={item?.label}
                  value={item.value}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={(e) => item?.onChange(e, index, item)}
                  sx={{
                    width: '100%',
                  }}
                  {...item?.props}
                />
              )}
            </Grid>
          );
        })}
        <Grid item xs={4} sm={6} md={2} lg={hasDownloadExcelButton ? 3 / 2 : 2}>
          <CustomButton
            type="submit"
            color={'buttonPrimary'}
            sx={{ mt: 1 }}
            onClick={() => {
              let arr =
                filtersArray?.filter((e) => e.isVisible) || filtersArray;
              let allDatavalues = {};
              let dataWithValue = {};

              arr?.forEach((element) => {
                allDatavalues = {
                  ...allDatavalues,
                  [element?.id]: element?.value,
                };
                if (element.value) {
                  dataWithValue = {
                    ...dataWithValue,
                    [element?.id]: element?.value,
                  };
                }
              });
              handleClick(arr, allDatavalues, dataWithValue);
            }}
          >
            Submit
          </CustomButton>
        </Grid>
        <Grid item xs={8} sm={6} md={6} lg={hasDownloadExcelButton ? 5 / 2 : 2}>
          <CustomButton
            type="submit"
            color={'buttonSecondary'}
            sx={{ mt: 1 }}
            onClick={() => {
              handleClear();
            }}
          >
            Clear Filter
          </CustomButton>
          {hasDownloadExcelButton && (
            <CustomButton
              type="submit"
              color={'buttonSecondary'}
              sx={{ mt: 1 }}
              onClick={() => {
                handleDownloadExcel();
              }}
            >
              Download Excel
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default MasterReportFilters;
