import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ContributorChat from './ContributorChat'

function ContributorChatContainer() {
  return (
    <MainLayout navTitle={"Order Details"} navSubTitle={"Welcome to ImagesBazaar"}>
        <ContributorChat/>
    </MainLayout>
  )
}

export default ContributorChatContainer