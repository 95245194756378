import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import {
  createRejections,
  editRejectionreasons,
} from '../../Services/cmsService';

const limit = DATA_LIMIT;

function AddEditReasonModal({ open, handleOpen, data, type, handleLast }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    try {
      setLoading(true);
      if (values.reason) {
        let formattedReason = values.reason;
        let resp = false;
        if (type == 'add') {
          let payload = {
            ...values,
            reason: formattedReason,
          };
          resp = await createRejections(payload);
        }
        if (type == 'edit') {
            let reason_ID = String(data.reason_id);
            let payload = {
            ...values,
            reason: formattedReason,
            reason_id: reason_ID,
          };
          resp = await editRejectionreasons(payload);
        }
        if (resp) {
          resetForm();
          handleLast();
          handleOpen(false);
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      reason: data?.reason || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason is required.'),
    }),
    onSubmit: handleConfirm,
  });

  const handleChange = (content) => {
    if (content == '<p><br></p>') {
      formik.setFieldValue('reason', '');
    } else {
      formik.setFieldValue('reason', content);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type == 'add' ? 'Add Reason' : 'Edit Reason'}
        </DialogTitle>
        <DialogContent>
          <Box noValidate component="form" onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="reason"
                  label="Reason"
                  placeholder="Reason"
                  value={formik.values.reason}
                  multiline
                  maxRows={10}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.reason && Boolean(formik.errors.reason)}
                  helperText={formik.touched.reason && formik.errors.reason}
                  //   style={{ height: '150px' }}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                {type == 'add' ? 'Add' : 'Update'}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    reason: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditReasonModal;
