import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getObjectSubset,
  getNewParameters,
  getParameters,
  breakStringIntoLines,
  formatDate,
} from '../../lib/helper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import DeleteIcon from '@mui/icons-material/Delete';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector } from '../../redux/contributor';
import {
  deleteQueriesStatus,
  queriesList,
  updateQueriesStatus,
} from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllFilters from '../Order/AllFilters';
import { QueryStatus } from '../../data/contributorsData';
import PendingIcon from '@mui/icons-material/Pending';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ConfirmationModal from '../Modals/ConfirmationModal';

const column = [
  {
    label: 'Contributor ID',
    id: 'contributor_id',
    format: (columnData) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Query',
    id: 'queries',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData.toString() == '1' ? 'Resolved' : 'Pending'}
      </span>
    ),
  },
];

function ContributorsQueries() {
  // const [loading, setLoading] = useState(false);
  // const limitOptions = [10, 50, 100, 250];
  // const [limit, setLimit] = useState(limitOptions[0]);
  const limit = 10;
  const [tableListing, setTableListing] = useState([]);
  const { contributorQueriesList } = useSelector(contributorItemsSelector);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmUpdateStatus, setConfirmUpdateStatus] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Query status',
      id: 'status',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: QueryStatus,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const getOrderListing = async (params = {}) => {
    const selectedStatus =
      filtersArray.find((filter) => filter.id === 'status')?.value || '';
    const payload = {
      page: String(page),
      limit: String(limit),
      search: allParams?.search,
      status: selectedStatus,
      ...allParams,
      ...params,
    };
    dispatch(queriesList(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getOrderListing();
  }, []);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      newState[1].isVisible = true;
      newState[1].value = '';
      return newState;
    });
  };

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }

    getOrderListing();
  }, [allParams]);

  useEffect(() => {
    if (contributorQueriesList?.items?.rows?.length >= 0) {
      let data = contributorQueriesList?.items?.rows?.map((item) => {
        return {
          name: item?.contributor?.name,
          email: item?.contributor?.email,
          contributor_id: item?.contributor?.contributor_id,
          queries: item?.queries,
          ci_id: item?.ci_id,
          status: item?.status,
          created_at: item?.created_at,
        };
      });
      setTableListing(data);
    }
  }, [contributorQueriesList.items]);

  const handleCofirmModalOpen = (value) => {
    setConfirmUpdateStatus(value);
  };

  const handleCofirmModalDeleteOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleConfirmBlock = async (value) => {
    if (value && selectedData) {
      let payload = {
        ci_id: selectedData?.ci_id,
        status: selectedData?.status == 1 ? '0' : '1',
      };
      let resp = await updateQueriesStatus(payload);
      if (resp) {
        getOrderListing({ page: '1' });
        setPage(1);
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };

  const handleConfirmDelete = async (value) => {
    if (value && selectedData) {
      console.log(selectedData,"selectedData")
      let payload = { ci_id: selectedData?.ci_id };
      let resp = await deleteQueriesStatus(payload);
      if (resp) {
        getOrderListing({ page: '1' });
        setPage(1);
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          {data?.status == 1 &&
          <CustomButton
            toolTipTittle={'Remove'}
            onClick={() => {
              handleCofirmModalDeleteOpen(true);
              setSelectedData(data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <DeleteIcon />
          </CustomButton>
          }

          {data?.status == 1 ? (
            <>
              <CustomButton
                toolTipTittle={'Pending'}
                onClick={() => {
                  // handleEdit(true, data)
                  handleCofirmModalOpen(true);
                  setSelectedData(data);
                  // setBlockUnBlock(data);
                }}
                className="buttonDense noMinWidth"
                sx={{
                  bgcolor: 'buttonSuccess.main',
                  color: 'buttonSuccess.contrastText',
                }}
              >
                <PendingIcon />
              </CustomButton>
            </>
          ) : (
            <>
              <CustomButton
                toolTipTittle={'Resolve'}
                onClick={() => {
                  // handleEdit(true, data)
                  handleCofirmModalOpen(true);
                  setSelectedData(data);
                  // setBlockUnBlock(data);
                }}
                className="buttonDense noMinWidth"
                sx={{
                  bgcolor: 'buttonDanger.main',
                  color: 'buttonDanger.contrastText',
                }}
              >
                <MoreHorizIcon />
              </CustomButton>
            </>
          )}
          {data?.status == 0 && (
            <CustomButton
              toolTipTittle={'Check Query Thread'}
              onClick={() => {
                // handleEdit(true, data)
                handleCofirmModalOpen(true);
                setSelectedData(data);
                // setBlockUnBlock(data);
              }}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonSecondary.main',
                color: 'buttonSecondary.contrastText',
              }}
            >
              {/* <Link to={`queryThread/${data?.ci_id}`}> */}
              <Link
                to={`queryThread?qid=${data?.ci_id}&id=${data?.contributor_id}&query=${data?.queries}`}
                style={{ color: 'white' }}
              >
                <ChatIcon />
              </Link>
            </CustomButton>
          )}
        </div>
      </>
    );
  };

  return (
    <CustomBox
      padding={0}
      sx={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MatTable
        loading={contributorQueriesList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={contributorQueriesList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        action={actionBody}
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {confirmUpdateStatus && (
        <ConfirmationModal
          open={confirmUpdateStatus}
          handleConfirm={handleConfirmBlock}
          setOpen={handleCofirmModalOpen}
          confirmationTitle={'Update Queries Status'}
        />
      )}
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDelete}
          setOpen={handleCofirmModalDeleteOpen}
          confirmationTitle={'Delete'}
        />
      )}
    </CustomBox>
  );
}

export default ContributorsQueries;
